import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import Config from '../config'

import Container from "../components/container"
import Header from "../components/header"
import Navbar from "../components/navbar"
import SEO from "../components/seo"
import Footer from "../components/footer"

const TEMPLATE = "blogPageTemplate"
const ALL = "all"

const Posts = ({ posts }) => {
  const truncateContent = (content, index) => {
    if (content.length > index) {
      return `${content.slice(0, index)}...`
    }
    return content
  }

  const goTo = slug => {
    navigate(`/blog/${slug}`)
  }

  return (
    <>
      {posts.length &&
        posts.map((item, i) => (
          <div className="col-lg-4" key={i}>
            <div style={{ marginTop: "32px", cursor: 'pointer' }} onClick={() => goTo(item.slug)} onKeyDown={() => goTo(item.slug)} role="button" tabIndex={0}>
              <div className="about-img">
                <img
                  src={item.banner?.data.full_url}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div
                className="blog-box"
                style={{ padding: "20px 30px 50px 30px" }}
              >
                <div>
                  <h5>{item.title}</h5>
                  {item.template && item.template === TEMPLATE ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: truncateContent(item.content, 150),
                      }}
                    ></div>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: truncateContent(
                          item.paragraphe[0].content,
                          150
                        ),
                      }}
                    ></div>
                  )}
                </div>
                <div style={{ marginTop: "16px" }}>
                  <Link to={`/blog/${item.slug}`} style={{ float: "right" }}>
                    Lire la suite
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  )
}

const BlogTemplate = ({ pageContext }) => {
  const { node } = pageContext
  const [data, setData] = useState({})
  const [blogPosts, setBlogPosts] = useState([])
  const [posts, setPosts] = useState([])
  const [categories, setCategories] = useState([])

  const getData = async stringID => {
    const id = stringID.match(/(\d+)/)[0]
    const call = await fetch(
      `${Config.apiUrl}/items/pages/${id}?access_token=${Config.token}&fields=*.*.*`
    )
    const response = await call.json()
    setData(response.data)
  }

  const getPosts = async () => {
    const call = await fetch(
      `${Config.apiUrl}/items/blogs?access_token=${Config.token}&fields=*.*.*&filter[status]=published&sort=-sort`
    )
    const response = await call.json()
    setPosts(response.data)
  }

  const getCategories = async () => {
    const call = await fetch(
      `${Config.apiUrl}/items/blog_categories?access_token=${Config.token}&fields=*.*.*&filter[status]=published`
    )
    const response = await call.json()
    setCategories(response.data)
  }

  const getBlogPosts = category => {
    if (category === ALL) {
      setBlogPosts(posts)
    } else {
      const array = posts.filter(item => item.cat.name === category)
      setBlogPosts(array)
    }
  }
  useEffect(() => {
    getData(node.id)
    getCategories()
    getPosts()
  }, [node])

  useEffect(() => {
    setBlogPosts(posts)
  }, [posts])

  return (
    <>
      <SEO
        title={node.meta_title}
        desc={node.meta_desc}
        keywords={node.meta_keywords}
        ogTitle={node.og_title}
        ogDesc={node.og_desc}
        ogImage={node.og_image?.data.full_url}
      />

      <Container>
        <Navbar />

        {data.banner_display && (
          <Header
            image={data.banner?.data.full_url}
            title={data.title}
            subtitle={data.subtitle}
          />
        )}

        <main id="main">
          <section id="contact" className="contact">
            <div className="container">
              <div
                className="section-title"
                dangerouslySetInnerHTML={{ __html: data.content }}
              ></div>
            </div>
            <div className="container">
              {categories.length > 1 ? (
                <div className="row">
                  <div className="col-lg-2">
                    <ul style={{ marginTop: "25px" }}>
                      <li key="tout">
                        <div
                          onClick={() => getBlogPosts(ALL)}
                          onKeyDown={() => getBlogPosts(ALL)}
                          role="button"
                          tabIndex="0"
                        >
                          Toutes les categories
                        </div>
                      </li>
                      {categories.length &&
                        categories.map(item => (
                          <li key={item.name}>
                            <div
                              onClick={() => getBlogPosts(item.name)}
                              onKeyDown={() => getBlogPosts(item.name)}
                              role="button"
                              tabIndex="0"
                            >
                              {item.name}
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="col-lg-10">
                    <div className="row">
                      {blogPosts.length ? (
                        <Posts posts={blogPosts} />
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          Aucun article trouvé
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  {blogPosts.length ? (
                    <Posts posts={blogPosts} />
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      Aucun article trouvé
                    </div>
                  )}
                </div>
              )}
            </div>
          </section>
        </main>

        <Footer />
      </Container>
    </>
  )
}

export default BlogTemplate
